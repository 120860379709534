<template>
  <b-row>
    <b-col
      cols="12"
      lg="6"
      class="mx-auto"
    >
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        layout="horizontal"
        finish-button-text="Confirmar"
        next-button-text="Siguiente"
        back-button-text="Regresar"
        class="wizard-vertical mb-3"
        shape="square"
        step-size="sm"
        @on-complete="handleGenerateInvoice"
      >  
        <tab-content title="Datos">
          <b-card no-body>  
            <b-card-header>
              <b-card-title>
                Ingresa datos de factura
              </b-card-title>
            </b-card-header>
  
            <b-card-text class="mx-2">
              Tipo de CFDI
            </b-card-text>
            <v-select
              v-model="selectedCfdiType"
              :options="catalogsSat.cfdi_types"
              class="mx-2 mb-2"
              :reduce="val => val.name"
              label="name"
              name="CfdiType"
            />

            <b-card-text class="mx-2">
              Tipo de pago
            </b-card-text>
            <b-row>
              <b-col>
                <v-select
                  v-model="selectedPaymentMethod"
                  class="mx-2 mb-2"
                  :reduce="val => val.value"
                  :options="setPaymentMethods"
                  label="name"
                />
              </b-col>
            </b-row>

            <b-card-text class="mx-2">
              Beneficiario
            </b-card-text>
            <b-row>
              <b-col>
                <v-select
                  v-model="selectedPayee"
                  class="mx-2 mb-2"
                  :options="payeeTaxes"
                  label="legal_name"
                />
              </b-col>
              <b-col>
                <b-button
                  variant="primary"
                  @click="$bvModal.show('existingPayeesModal')"
                >
                <span class="text-nowrap">Agregar beneficiario</span>
                </b-button>
              </b-col>
            </b-row>


            <b-row
              v-if="selectedPayee"
            >
              <b-form-group class="px-2">
                <b-form-group id="taxId" label="RFC" label-for="taxId">
                  <b-form-input disabled :value="selectedPayee ? selectedPayee.tax_id : ''" class="mb-1" />
                </b-form-group>
                <b-form-group id="legalName" label="Nombre legal" label-for="legalName">
                  <b-form-input disabled :value="selectedPayee ? selectedPayee.legal_name : ''" class="mb-1" />
                </b-form-group>
                <b-form-group id="zipCode" label="Codigo postal" label-for="zipCode">
                  <b-form-input disabled :value="selectedPayee ? selectedPayee.tax_zip_code : ''" class="mb-1" />
                </b-form-group>
                <b-form-group id="email" label="E-mail" label-for="email">
                  <b-form-input disabled :value="selectedPayee ? selectedPayee.email : ''" class="mb-1" />
                </b-form-group>
              </b-form-group>
            </b-row>

          </b-card>
        </tab-content>
        <tab-content title="Tienda" v-if="!queryOrder">
          <b-card no-body>  
            <b-card-header>
              <b-card-title>
                Selecciona tienda
              </b-card-title>
            </b-card-header>
            <v-select
              v-model="selectedStore"
              class="mx-2 mb-2"
              :options="onlineStores"
              label="name"
            />
          </b-card>
        </tab-content>
        <tab-content title="Ordenes" v-if="!queryOrder">
          <invoice-orders-list 
            :selectedStore="selectedStore"
            :handleCheckboxClick="handleCheckboxClick"
            :loading="loading"
          />
        </tab-content>
      </form-wizard>
    </b-col>
    <add-payee-modal 
      :establishmentId="establishmentId" 
    />
  </b-row>
</template>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import vSelect from 'vue-select'
import InvoiceOrdersList from './InvoiceOrdersList.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import '@/@core/scss/vue/libs/vue-wizard.scss'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import AddPayeeModal from '@/views/account-setting/AddPayeeModal.vue'
import catalogsSat from '@/store/catalogsSat'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BLink,
    vSelect,
    InvoiceOrdersList,
    FormWizard,
    TabContent,
    AddPayeeModal,
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      selectedPayee: null,
      loading: false,
      establishmentId: null,
      selectedStore: null,
      selectedPaymentForm: null,
      selectedPaymentMethod: null,
      selectedCfdiType: null,
      selectedPaymentMethod: null,
      paymentMethod: [
        {
          name: 'PUE',
          value: 'PUE',
        },
        {
          name: 'PPD',
          value: 'PPD',
        },
      ],
      queryOrder: this.$route.query.order_id,
    }
  },
  computed: {
    ...mapGetters('payees', {payeeTaxes: 'payeeTaxes', payeePagination: 'pagination'}),
    ...mapGetters('catalogsSat', ['catalogsSat']),
    ...mapGetters('stores', ['onlineStores']),
    ...mapGetters('invoices', ['selectedOrders']),
    
    setPaymentMethods(){
      return this.userData.role_name === 'customer' ? this.paymentMethod.filter(x => x.value !== 'PDD') : this.paymentMethod 
    },
  },
  beforeMount(){
    const establishment = this.userData.scoped_roles.filter(x => x.role_resource_type === 'Establishment') 
    this.establishmentId = establishment.length > 0 ? establishment[0].role_resource_id : null
    this.fetchPayeeTaxes({
      by_establishment: this.establishmentId,
      by_active_status: 'active',
      meta: {
        pagination: {
          page: 1,
          per_page: 1000,
        },
      }
    })

    if(!this.catalogsSat || 
      !this.catalogsSat.cfdiTypes?.length || 
      !this.catalogsSat.fiscalRegimes?.length || 
      !this.catalogsSat.paymentForms?.length || 
      !this.catalogsSat.paymentMethods?.length) {
      this.fetchCatalogsSat()
    }
        
    this.fetchOnlineStores({by_establishment: this.$route.params.id, meta: {pagination: {page: 1, per_page: 100000}}})
  },
  methods: {
    ...mapActions('payees', ['registerPayee', 'fetchPayeeTaxes']),
    ...mapActions('invoices', ['registerInvoice']),
    ...mapActions('catalogsSat', ['fetchCatalogsSat']),
    ...mapActions('stores', ['fetchOnlineStores']),
    ...mapMutations('invoices', ['addSelectedOrder', 'removeSelectedOrder']),

    handleCheckboxClick(orderId){      
      if(this.selectedOrders.includes(orderId)){
        this.removeSelectedOrder(orderId)
      } else {
        this.addSelectedOrder(orderId)
      }
    },

    handleGenerateInvoice(){
      if(!this.selectedCfdiType){
        this.$bvToast.toast('Selecciona un tipo de cfdi', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        return
      }

      if(!this.selectedPayee){
        this.$bvToast.toast('Selecciona un beneficiario', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        return
      }

      if(this.selectedOrders.length === 0 && !this.queryOrder){
        this.$bvToast.toast('Selecciona al menos una orden', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        return
      }

      this.$swal({
        title: 'Generando factura...',
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        },
      })
      
      const selectedCfdiType = this.catalogsSat.cfdi_types.find(x => x.name === this.selectedCfdiType).value

      this.registerInvoice({
        cfdi_type: selectedCfdiType,
        payment_method: this.selectedPaymentMethod,
        receiver: this.selectedPayee.id,
        orders: this.selectedOrders.length ? this.selectedOrders : [this.$route.query.order_id],
      }).then(res => {
        this.$swal.close()
        if(this.userData.role_name === 'customer'){
          this.$router.push({ name: 'home' })
        }else{
          this.$router.push({ name: 'select-online-invoice' })
        }
      }).catch(err => {
        console.log(err)
        const errorMessage = err.response.data.messages
        this.$swal({
          title: errorMessage && errorMessage.length > 2 ? errorMessage : errorMessage[0] ? errorMessage[0] : 'Error al generar factura',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },

  }
}
</script>

<style lang="scss">
  .vue-form-wizard {
    .wizard-header {
      display: none;
    }
    ul.wizard-nav-pills,
    .wizard-progress-with-circle {
      display: none !important;
    }
  }

</style>