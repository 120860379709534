<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title>
        Seleccionar ordenes a facturar
      </b-card-title>
    </b-card-header>
   
    <div class="d-flex align-items-center justify-content-end mb-2">
      <b-form-input
        v-model="searchQuery"
        class="d-inline-block mx-2"
        placeholder="Buscar orden..."
      />
    </div>
  
    <ul v-if="orders"
      class="m-0 p-0"
    > 
      <b-media 
        v-for="order in orders"
        :key="order.id"
        no-body 
        tag="li"
        class="mb-1"
      >
        <b-media-body>
          <b-form-checkbox class="d-flex align-items-center mx-1" 
            :value="order.id" 
            @change="handleCheckboxClick(order.id)" 
          >
            <div class="d-flex justify-content-between align-items-center order-flex">
              <h6
                v-if="order.transactable && order.transactable.payment_status && !order.transactable.auto_inc_folio"
              >
                {{ transactionName(order.transactable.payment_status) }}
              </h6>
              <p
                v-else-if="order.auto_inc_folio"
              >
                <h6>
                  {{ transactionName(order.order_type) }}
                </h6>
              </p>

              <b-badge
                v-if="order.transactable && !order.transactable.auto_inc_folio"
                pill
                :variant="`light-${paymentTypeVariant(order.transactable.payment_type)}`"
              >
                {{ order.transactable.payment_type | paymentType }}
              </b-badge>
              <b-badge
                v-else-if="order.order_payments_attributes"
                pill
                :variant="`light-${paymentTypeVariant(order.order_payments_attributes[0].payment_type)}`"
              >
                {{ order.order_payments_attributes[0].payment_type | paymentType }}
              </b-badge>
              <b-badge
                v-else-if="!order.order_payments_attributes && !order.transactable"
                pill
                :variant="`light-${paymentTypeVariant(order.payment_type)}`"
              >
                {{ order.payment_type | paymentTypeNum }}
              </b-badge>
              <small v-if="(order.transactable && !order.transactable.auto_inc_folio) || order.order_type">
                {{ order.created_at | date }}
              </small>
          
              <div>
                <div
                  v-if="order.transactable && !order.transactable.auto_inc_folio"
                  class="font-weight-bolder"
                  :class="`text-${transactionIconVariant(order.transactable.payment_status)}`"
                >
                  {{ order.transactable.amount ? '+' : '-' }} ${{ order.transactable.amount || order.transactable.total | money }}
                </div>
                <div
                  v-else-if="!order.transaction_type"
                  class="font-weight-bolder"
                  :class="`text-${transactionIconVariant(order.payment_status)}`"
                >
                  {{ '-' }} ${{ order.total | money }}
                </div>
              </div>
              <div>
                <div
                  v-if="order.auto_inc_folio"
                >
                  - Folio 
                  <span class="font-weight-bolder">
                    {{ order.auto_inc_folio }}
                  </span>
                </div>
              </div>
            </div>
          </b-form-checkbox>
        </b-media-body>
      </b-media>  
    </ul>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center mx-auto"
        >

          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.total_objects"
            :per-page="pagination.per_page"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="(value)=>{handleOrdersPagination({ page: value, per_page: pagination.per_page })}"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <b-spinner
                v-if="loading"
                small
                label="Loading"
              />
              <feather-icon
                v-else
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCol,
  BFormCheckbox,
  BMedia,
  BMediaBody,
  BRow,
  BPagination,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import _ from 'underscore'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCol,
    BFormCheckbox,
    BMedia,
    BMediaBody,
    BRow,
    BPagination,
  },
  props: {
    selectedStore: {
      type: Object,
      default: () => {},
    },
    handleCheckboxClick: {
      type: Function,
      default: () => {},
    },
  },
  data(){
    return {
      loading: false,
      searchQuery: '',
    }
  },
  computed: {
    ...mapGetters('orders', ['orders', 'pagination']),
    ...mapGetters('invoice', ['selectedOrders']),
  },
  watch: {
    selectedStore: {
      handler: function (val, oldVal) {
        if(val){
          this.fetchOrders({
            by_invoicing: 'not_invoiced',
            by_payment_status: 'payed',
            by_store: val.id,
          })
          this.clearSelectedOrders()
        } 
      },
      deep: true,
    },
    searchQuery: {
      handler: _.debounce(function (val, oldVal) {
        this.fetchOrders({
          by_auto_folio: val,
        })
      }, 500),
      deep: true,
    }
  },
  methods: {
    ...mapActions('orders', ['fetchOrders']),
    ...mapMutations('invoices', ['clearSelectedOrders']),
    handleOrdersPagination({ page, per_page }) {
      this.loading = true
      this.fetchOrders({
        by_store: this.selectedStore.id,
        by_invoicing: 'not_invoiced',
        meta: {
          pagination: {
            page,
            per_page,
          },
        },
      })
      .finally(() => {
        this.loading = false
      })
    },

    transactionIconVariant(status) {
      const statusList = {
        debit: 'warning',
        credit: 'success',
        sell: 'warning',
        emida: 'success',
        loan_payment: 'success'
      }
      return statusList[status] ? statusList[status] : 'primary'
    },
    paymentTypeVariant(status) {
      const statusList = {
        walleat: 'primary',
        cash: 'warning',
        codi: 'info',
        reward_points: 'success',
        bankcard: 'secondary'
      }
      return statusList[status] ? statusList[status] : 'primary'
    },
    transactionName(status) {
      const statusList = {
        payed: 'Pago exitoso',
        pending: 'Operación pendiente',
        cancelled: 'Operación cancelada',
        sell: 'Compra de productos',
        emida: 'Pago de servicios/compra de saldo',
        loan_payment: 'Abonaste a la deuda',
      }
      return statusList[status] ? statusList[status] : 'Error'
    },
  }
}

</script>

<style lang="scss" scoped>
  .order-flex{
    gap: .5rem;
    flex-wrap: wrap;
  }
</style>